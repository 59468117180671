<template>
  <div style="width: 100%">
    <el-input type="text" v-model="showInputPostion" placeholder="请选择" @click="bindShowDialog" style="width: 100%">
      <template #append>
        <el-button type="primary" @click="bindShowDialog" >修改坐标位置</el-button>
      </template>
    </el-input>
  </div>
  <!-- 弹框 -->
  <el-dialog v-model="dialogFormVisible" title="选择地址" width="60%">
    <!--百度地图-->
    <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :mapClick="false"
        mapType="BMAP_HYBRID_MAP"
        :scroll-wheel-zoom="true"
        @click="getPoint">
      <!--标记物-->
      <bm-marker :position="markPostion" :dragging="false" @click="infoWinOpen"></bm-marker>
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
      <bm-info-window :position="markPostion" :offset="{width:-3,height:-30}" ref="customOverlay" title="当前坐标"
                      :show="infoWindow.show" @close="infoWinClose" @open="infoWinOpen">
        <div>经度：{{ markPostion.lng }}</div>
        <div>纬度：{{ markPostion.lat }}</div>
      </bm-info-window>
<!--      <bm-boundary name="四川省阿坝藏族羌族自治州松潘县" :strokeWeight="2" strokeColor="blue" fillOpacity="0.1"></bm-boundary>-->
<!--      <bm-boundary name="四川省绵阳市安州区" :strokeWeight="2" strokeColor="blue" fillOpacity="0.1"></bm-boundary>-->
      <bm-boundary name="四川省德阳市罗江区" :strokeWeight="2" strokeColor="blue" fillOpacity="0.1"></bm-boundary>
    </baidu-map>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="getNewPosition">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref, toRefs, defineProps, defineEmits} from "vue";

const emits = defineEmits(['getPoint'])

const customOverlay = ref(null)
/*接受传下来的传感器坐标*/
const props = defineProps({
  center: Object
})
const {center} = toRefs(props)

let markPostion = ref({})
markPostion.value = center.value
let zoom = ref(12)

let showInputPostion = ref()

/*弹框*/
let dialogFormVisible = ref(false)

const infoWindow = ref({
  show: true,
});

const infoWinClose = (e) => {
  // infoWindow.value.show = false
};

const infoWinOpen = (e) => {
  infoWindow.value.show = true
};

/*测试弹框*/
const bindShowDialog = () => {
  dialogFormVisible.value = true
}
/*修改input框里面的参数*/
const getNewPosition = () => {
  dialogFormVisible.value = false
  showInputPostion.value = '坐标位置:经度' + markPostion.value.lng + "  纬度" + markPostion.value.lat
  emits('getPoint',{
    address: showInputPostion.value,
    longitude: markPostion.value.lng,
    latitude: markPostion.value.lat
  })
}


/*点击获取地图参数*/
const getPoint = (e) => {
  markPostion.value = e.point
  infoWindow.value.show = true
  customOverlay.value.redraw()
}
</script>

<style lang="less" scoped>

.map {
  width: 100%;
  height: 600px;
}

:deep(.BMap_cpyCtrl)  {
  display: none;
}

:deep(.anchorBL)  {
  display: none;
}
</style>
