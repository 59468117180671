import Vuex from 'vuex'

const localData = ['vuex_tenantInfo','vuex_tenantId','vuex_token','vuex_userInfo',
    'vuex_menuList','vuex_buttonScopeList','vuex_routes','vuex_loginRouter','vuex_townInfo','vuex_enterprise']

const state = {
    vuex_tenantInfo: {},
    vuex_routes: [],
    vuex_uploadFileUrl: "/api/file/file/upload",
    // vuex_uploadFileUrl: "/hwUploadApi",
    vuex_tenantId: '',
    vuex_previewUrl: '/api/file/file/preview?id=',
    // vuex_previewUrl: 'https://ssyn.obs.cn-south-1.myhuaweicloud.com/server/',
    vuex_token: '',
    vuex_userInfo: {},
    vuex_menuList: [],
    vuex_buttonScopeList: [],
    vuex_lockPassword: '',
    vuex_townInfo: {},
    vuex_loginRouter: '',
    vuex_enterprise: {},
    vuex_obj:{},
    vuex_curTab_id:0,
    vuex_proInfo: {}
}
const mutations = {
    setData(state,data) {
        state[data.prop] = data
        if (localData.indexOf(data.prop) !== -1) {
           localStorage.setItem('ssyn_'+data.prop,JSON.stringify(data.data))
        }
    },
}
const getters = {
    getData(state){
        return function(prop) {
            if ((!state[prop] || !state[prop].length) && (localData.indexOf(prop) !== -1)) {
                return JSON.parse(localStorage.getItem('ssyn_'+prop))
            } else {
                return state[prop]
            }
        }
    }
}

const actions = {

}
export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions
})
