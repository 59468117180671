<template>
  <div>
    <el-dialog title="详情" v-model="visible" :append-to-body="true">
      <div class="padding-30">
        <el-form size="large" ref="a_form" :model="formData" :label-width="labelWidth ? labelWidth : '100px'" label-position="right">
          <template v-for="(item,index) in infoConfig">
            <el-form-item :label="item.label" :prop="item.prop">
              <div v-if="item.unitLable" class="info-text">
                {{formData[item.prop] +' ' + item.unitLable}}
              </div>
<!--              <div v-else-if="item.type === 'img_single'">-->
<!--                <img @click="showImg(formData[item.prop])" :src="$store.state.vuex_previewUrl + $store.getters.getData('vuex_tenantId') + '/' + formData[item.prop]" style="width: 160px;height: 160px;border-radius: 4px">-->
<!--              </div>-->
<!--              <div v-else-if="item.type === 'imgs'" class="flex flex-wrap align-center">-->
<!--                <template v-for="(img,k) in item.imgsArr" :key="k">-->
<!--                  <img @click="showImg(img)" v-if="img" :src="$store.state.vuex_previewUrl + $store.getters.getData('vuex_tenantId') + '/' + img" style="width: 160px;height: 160px;margin-right: 20px;margin-bottom:20px;border-radius: 4px">-->
<!--                </template>-->
<!--              </div>-->

              <div v-else-if="item.type === 'img_single'">
                <img @click="showImg(formData[item.prop])" :src="$store.state.vuex_previewUrl  + formData[item.prop]" style="width: 160px;height: 160px;border-radius: 4px">
              </div>
              <div v-else-if="item.type === 'imgs'" class="flex flex-wrap align-center">
                <template v-for="(img,k) in item.imgsArr" :key="k">
                  <img @click="showImg(img)" v-if="img" :src="$store.state.vuex_previewUrl  + img" style="width: 160px;height: 160px;margin-right: 20px;margin-bottom:20px;border-radius: 4px">
                </template>
              </div>

              <div v-else-if="item.type === 'tag'">
                <el-tag v-for="(tag,index) in item.tagList" size="large" class="mr-10">{{tag[item.props.label]}}</el-tag>
              </div>
              <div v-else-if="item.type === 'time'" class="info-text">{{getTimeText(formData[item.prop],item)}}</div>
              <div v-else-if="item.type === 'html'" v-html="formData[item.prop]" style="width: 100%;"></div>
              <div v-else class="info-text" >{{formData[item.prop]}}</div>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="imgVisible" :append-to-body="true">
      <img w-full :src="dialogImageUrl" style="width: 100%" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script>
let moment = require('moment');
moment.locale('zh-cn')
export default {
  props:{
    api:{
      default: '',
      type: String,
    },
    infoConfig:{
      default: [],
      type: Array,
    },
    labelWidth:{
      type: String
    }
  },
  data() {
    return {
      formData:{},
      visible: false,
      imgVisible: false,
      dialogImageUrl: ''
    }
  },
  methods: {
    getTimeText(value,item){
      return moment(value).format(item.format)
    },
    show(data){
      this.visible = true
      this.formData = Object.assign({},data)

      this.infoConfig.forEach(item=>{
        if (item.api) {
          this.$axios.post(item.api).then(res=>{
            let list = res.data
            if (item.pObj) {
              list.push(item.pObj)
            }
            if(item.type === 'tag') {
              let arrStr = this.formData[item.prop]
              item.tagList = []
              if(arrStr) {
                let idArr = arrStr.split(',')
                idArr.forEach(el=>{
                  let arr = list.filter(obj => obj[item.props.value] === el)
                  if(arr.length > 0) {
                    item.tagList.push(arr[0])
                  }
                })
              }

            } else {
              let arr = list.filter(obj => obj[item.props.value] === this.formData[item.prop])
              if(arr.length > 0) {
                this.formData[item.prop] = arr[0][item.props.label]
              } else {
                this.formData[item.prop] = '未匹配'
              }
            }
          })
        }
        if (item.apis) {
          this.$axios.post(item.apis).then(res=>{
            let list = res.data
            if (item.pObj) {
              list.push(item.pObj)
            }
            let arrStr = this.formData[item.prop]
            let labelArr = []
            if(arrStr) {
              let idArr = arrStr.split(',')
              idArr.forEach(el=>{
                let arr = list.filter(obj => obj[item.props.value] === el)
                if(arr.length > 0) {
                  labelArr.push(arr[0][item.props.label])
                } else {
                  labelArr.push('未匹配')
                }
              })
            }
            this.formData[item.prop] = labelArr.join(',')
          })
        }
        if (item.dict) {
          if (item.type === 'checkbox') {
            let arr = this.formData[item.prop] ? this.formData[item.prop].split(',') : []
            let labelArr = []
            arr.forEach(val=>{
              labelArr.push(this.$filter.getDictLabel(item.dict,val))
            })
            this.formData[item.prop] = labelArr.join(',')
          } else {
            this.formData[item.prop] = this.$filter.getDictLabel(item.dict,this.formData[item.prop])
          }
        }
        if (item.type === 'imgs') {
          item.imgsArr = this.formData[item.prop] ? this.formData[item.prop].split(',') : []
        }
      })
    },
    showImg(imgId){
      this.imgVisible = true
      this.dialogImageUrl = this.$store.state.vuex_previewUrl + this.$store.getters.getData('vuex_tenantId') + '/' + imgId
    },
  }
}
</script>

<style lang="less" scoped>
  .info-text {
    font-family: 'Alibaba-PuHuiTi-Regular';
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
    padding: 0 20px;
    border-radius: 4px
  }
</style>
