import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from '@/store'
const routes = [
    {
        path: "/HwOneImage",
        name:'HwOneImage',
        component: ()=>import('@/components/HwOneImage'),
    },
    {
        path: "/flowable-edit",
        name:'flowable-edit',
        component: ()=>import('@/views/flowable/definition/edit'),
    },
    {
        path: "/flowable-design",
        name:'flowable-design',
        component: ()=>import('@/views/flowable/definition/design'),
    },
    {
        path: "/",
        name:'index',
        component: ()=>import('@/views/index/index'),
    },
    {
        path: "/ljLogin",
        name: "ljLogin",
        component: ()=>import('@/views/login/ljLogin'),
    },
    {
        path: "/ljhome",
        name: "ljhome",
        component: ()=>import('@/views/luojiang/home'),
    },
    {
        path: "/ljqx",
        name: "ljqx",
        component: ()=>import('@/views/luojiang/qx'),
    },
    {
        path: "/ljbch",
        name: "ljbch",
        component: ()=>import('@/views/luojiang/bch'),
    },
    {
        path: "/ljmobile",
        name: "ljmobile",
        component: ()=>import('@/views/mobile/index'),
    },
    {
        path: "/weatherDataShow",
        name: "weatherDataShow",
        component: ()=>import('@/views/equipShow/weatherDataShow'),
    },
    {
        path: "/soilDataShow",
        name: "soilDataShow",
        component: ()=>import('@/views/equipShow/soilDataShow'),
    },
    {
        path: "/insectDataShow",
        name: "insectDataShow",
        component: ()=>import('@/views/equipShow/insectDataShow'),
    },
    {
        path: "/insectDataBz",
        name: "insectDataBz",
        component: ()=>import('@/views/equipShow/insectDataBaozi'),
    },
    {
        path: "/ytLogin",
        name: "ytLogin",
        component: ()=>import('@/views/login/ytLogin'),
    },
    {
        path: "/login",
        name: "login",
        component: ()=>import('@/views/login/index'),
    },
    {
        path: "/bigDataLogin",
        name: "bigDataLogin",
        component: ()=>import('@/views/login/bigDataLogin'),
    },
    {
        path: "/nav",
        name: "nav",
        component: ()=>import('@/views/productSafe/layout/nav'),
    },
    {
        path: "/admin",
        name: "admin",
        component: ()=>import('@/views/productSafe/layout/index'),
        children: [
            {
                path: '/setting',
                name: 'setting',
                component: () => import('@/views/productSafe/modular/system/user/setting'),
            },
        ]
    },
    {
        path: "/hyBigData",
        name: "hyBigData",
        component: ()=>import('@/views/HYBigData/index/index'),
    },
    {
        path: '/applyDetail',
        name: 'applyDetail',
        component: () => import('@/views/hyManage/details/applyDetail/index'),
    },
    {
        path: '/error_404',
        name: 'error_404',
        component: () => import('@/views/hyManage/error/index'),
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('@/views/hyManage/message/index'),
    },

    {
        path: "/hyDetails",
        name: "hyDetails",
        component: ()=>import('@/views/hyManage/details/index'),
        children: [
            {
                path: '/filelist',
                name: 'filelist',
                component: () => import('@/views/hyManage/details/fileList/index'),
            },
            {
                path: '/basic1',
                name: 'basic1',
                component: () => import('@/views/hyManage/details/basic1/index'),
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('@/views/hyManage/details/timeLine/index'),
            },
            {
                path: '/gantt',
                name: 'gantt',
                component: () => import('@/views/hyManage/details/gantt/index'),
            },
            {
                path: '/point',
                name: 'point',
                component: () => import('@/views/hyManage/details/applyList/index'),
            },
        ]
    },
    {
        path: "/file",
        name: "file",
        component: ()=>import('@/components/file/index'),
    },
    {
        path: "/hyLogin",
        name: "hyLogin",
        component: ()=>import('@/views/hyManage/login/index'),
    },
    {
        path: "/hyIndex",
        name: "hyIndex",
        component: ()=>import('@/views/hyManage/index/index'),
    },
    {
        path: "/townData",
        name: "townData",
        redirect: 'industry',
        component: ()=>import('@/views/townData/index/index'),
        meta:{
            keepAlive: false,
        },
        children: [
            {
                path: '/industry',
                name: 'industry',
                component: () => import('@/views/townData/industry/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/brand',
                name: 'brand',
                component: () => import('@/views/townData/brand/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/regulatory',
                name: 'regulatory',
                component: () => import('@/views/townData/regulatory/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/rules',
                name: 'rules',
                component: () => import('@/views/townData/rules/index'),
                meta:{
                    keepAlive: false,
                }
            },
        ]
    },
    {
        path:'/countyData',
        name:'countyData',
        redirect: 'dataRegulatory',
        component: ()=>import('@/views/countyData/index/index'),
        meta:{
            keepAlive: false,
        },
        children: [
            {
                path: '/dataRegulatory',
                name: 'dataRegulatory',
                component: () => import('@/views/countyData/regulatory/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataCredit',
                name: 'dataCredit',
                component: () => import('@/views/countyData/credit/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataHgz',
                name: 'dataHgz',
                component: () => import('@/views/countyData/hgz/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataCheck',
                name: 'dataCheck',
                component: () => import('@/views/countyData/check/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataSupervise',
                name: 'dataSupervise',
                component: () => import('@/views/countyData/supervision/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataTownNavbar',
                name: 'dataTownNavbar',
                component: () => import('@/views/countyData/townNavbar/index'),
                meta:{
                    keepAlive: false,
                }
            },
            {
                path: '/dataMoney',
                name: 'dataMoney',
                component: () => import('@/views/countyData/money/index'),
                meta:{
                    keepAlive: false,
                }
            },
        ]
    },
    {
        path: "/gen",
        name: "gen",
        component: ()=>import('@/views/gen/index'),
    },
    {
        path: "/equipShow",
        name: "equipShow",
        component: ()=>import('@/views/equipShow/home'),
    },
    {
        path: "/spjkgl",
        name: "spjkgl",
        component: ()=>import('@/views/equipShow/spjkgl'),
    },
    {
        path: "/qxInfo",
        name: "qxInfo",
        component: ()=>import('@/views/equipShow/qx'),
    },
    {
        path: "/bchInfo",
        name: "bchInfo",
        component: ()=>import('@/views/equipShow/bch'),
    },
    {
        path: "/nysc",
        name: "nysc",
        component: ()=>import('@/views/equipShow/nysc'),
    },
    {
        path: "/shhfw",
        name: "shhfw",
        component: ()=>import('@/views/equipShow/shhfw'),
    },
    {
        path: "/syxx",
        name: "syxx",
        component: ()=>import('@/views/equipShow/syxx'),
    },
    {
        path: "/enterpriseLogin",
        name: "enterpriseLogin",
        component: ()=>import('@/views/login/enterpriseLogin'),
    },
    {
        path: "/produce",
        name: "produce",
        redirect: 'recordLand',
        component: ()=>import('@/views/produce/home'),
        children: [
            {
                path: '/shumiao',
                name: 'shumiao',
                component: () => import('@/views/produce/warehouse/zhongzi/index'),
            },
            {
                path: '/feiliao',
                name: 'feiliao',
                component: () => import('@/views/produce/warehouse/feiliao/index'),
            },
            {
                path: '/nongyao',
                name: 'nongyao',
                component: () => import('@/views/produce/warehouse/nongyao/index'),
            },
            {
                path: '/recordLand',
                name: 'recordLand',
                component: () => import('@/views/produce/record/landRecord/index'),
            },
            {
                path: '/recordPlate',
                name: 'recordPlate',
                component: () => import('@/views/produce/record/cropRecord/index'),
            },
            {
                path: '/recordShifei',
                name: 'recordShifei',
                component: () => import('@/views/produce/record/shifei/index'),
            },
            {
                path: '/recordShiyao',
                name: 'recordShiyao',
                component: () => import('@/views/produce/record/shiyao/index'),
            },
            {
                path: '/recordCaizhai',
                name: 'recordCaizhai',
                component: () => import('@/views/produce/record/caizhai/index'),
            },
            {
                path: '/recordOther',
                name: 'recordOther',
                component: () => import('@/views/produce/record/other/index'),
            },
            {
                path: '/recordSell',
                name: 'recordSell',
                component: () => import('@/views/produce/record/sell/index'),
            },
        ]
    },
    {
        path: "/azHome",
        name: "azHome",
        component: ()=>import('@/views/az/home/index'),
    },
    {
        path: "/infoPub",
        name: "infoPub",
        redirect: 'infopub-home',
        component: ()=>import('@/views/az/infopub/home'),
        children: [
            {
                path: '/infopub-home',
                name: 'infopub-home',
                component: () => import('@/views/az/infopub/index'),
            },
            {
                path: "/goodsList",
                name: "goodsList",
                component: ()=>import('@/views/az/infopub/goodsList'),
            },
            {
                path: "/infopub-newsList",
                name: "infopub-newsList",
                component: ()=>import('@/views/az/infopub/newsList'),
            },
            {
                path: "/infopub-cgList",
                name: "infopub-cgList",
                component: ()=>import('@/views/az/infopub/cgList'),
            },
            {
                path: "/infopub-detail",
                name: "infopub-detail",
                component: ()=>import('@/views/az/infopub/detail'),
            },
            {
                path: "/infopub-newDetail",
                name: "infopub-newDetail",
                component: ()=>import('@/views/az/infopub/newsDetail'),
            },
            {
                path: "/infopub-form",
                name: "infopub-form",
                component: ()=>import('@/views/az/infopub/form'),
            },
        ]
    },
    {
        path: "/guidance",
        name: "guidance",
        component: ()=>import('@/views/az/expertguidance/main'),
    },
    {
        path: '/expertDetail',
        name: 'expertDetail',
        component: () => import('@/views/az/expertguidance/courseDetail'),
    },
    {
        path: '/productManage',
        name: 'productManage',
        component: () => import('@/views/productManage/index/index'),
    },
    {
        path: '/produceCategory',
        name: 'produceCategory',
        component: () => import('@/views/productManage/produceCategory/index'),
    },
    {
        path: '/produceForm',
        name: 'produceForm',
        component: () => import('@/views/productManage/produceForm/index'),
    },
    {
        path: '/produceTemplate',
        name: 'produceTemplate',
        component: () => import('@/views/productManage/produceTemplate/index'),
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

let flag = false
router.beforeEach(async (to, from, next)=>{
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //新增代码
    if (to.matched.length === 0 ){
        next("/error_404")
    }

    if (to.name === 'admin') {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
        }
        flag = true
        next()
    } else if (!flag) {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
            flag = true
            next({
                ...to,
                replace: true
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export function resetRouter(router) {

}

export default router
