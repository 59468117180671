<template>
  <div class="base-table" >
    <base-search v-if="searchConfig.length > 0" ref="baseSearch" :collectName="collectName" :searchConfig="searchConfig" @search="search" @clear="clear"></base-search>
    <div class="flex align-center justify-between">
      <div>
        <slot name="table-top-btn"></slot>
        <el-button v-if="(btnConfig.add.show && $filter.perValidate(btnConfig.add.permission)) || (!btnConfig.add.permission && btnConfig.add.label)" round icon="Plus" size="small" type="primary" @click="handleAdd()">{{btnConfig.add.label}}</el-button>
        <el-button v-if="btnConfig.deleteAll.show && $filter.perValidate(btnConfig.deleteAll.permission)" round icon="Delete" size="small" type="danger" @click="handleEdit()">{{btnConfig.deleteAll.label}}</el-button>
        <el-button v-if="btnConfig.export.show && $filter.perValidate(btnConfig.export.permission)" round size="small" type="warning" @click="">
          <svg-icon class="mr-4" icon-class="exportExcel"></svg-icon>{{btnConfig.export.label}}
        </el-button>
      </div>
      <div class="flex align-center"></div>
    </div>
    <el-table ref="tableRef" :data="tableData" class="mt-20" highlight-current-row  :header-cell-style="{backgroundColor: '#999999',color:'#FFFFFF'}">
      <el-table-column type="selection" width="55" />
      <template v-for="(item,index) in columns" :key="index">
        <el-table-column :prop="item.prop" :label="item.label" :width="item.width">
          <template #default="scope" >
            <span class="text-label" >{{getValue(scope.row,item)}}</span>
          </template>
        </el-table-column>
      </template>

      <el-table-column label="操作" v-if="!btnConfig.hidden" :width="btnConfig.width ? btnConfig.width : 300" fixed="right">
        <template #default="scope">
          <slot name="table-inner-btn" :data="scope"></slot>
          <el-button v-if="(btnConfig.info.show && $filter.perValidate(btnConfig.info.permission)) || (!btnConfig.add.permission && btnConfig.add.label)" icon="View" size="small" round type="info" @click="handleInfo(scope.$index, scope.row)">{{btnConfig.info.label}}</el-button>
          <el-button v-if="(btnConfig.update.show && $filter.perValidate(btnConfig.update.permission)) || (!btnConfig.add.permission && btnConfig.add.label)" icon="Edit" size="small" round type="primary" @click="handleEdit(scope.$index, scope.row)">{{btnConfig.update.label}}</el-button>
          <el-button v-if="(btnConfig.delete.show && $filter.perValidate(btnConfig.delete.permission)) || (!btnConfig.add.permission && btnConfig.add.label)" icon="Delete" size="small" round type="danger" @click="handleDelete(scope.$index, scope.row)">{{btnConfig.delete.label}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="page" size="small"
        :currentPage="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
    <base-form ref="baseForm" :formConfig="formConfig" :api="api" :rules="rules" @refresh="refresh" :defaultData="formParam" :labelWidth="labelWidth"></base-form>
    <base-info ref="baseInfo" :infoConfig="detailConfig" :labelWidth="labelWidth"></base-info>
  </div>
</template>

<script>
let moment = require('moment');
moment.locale('zh-cn')
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  props:{
    api: {
      default: '',
      type: String,
    },
    collectName: {
      default: '',
      type: String,
    },
    btnConfig: {
      default: {
        add:{
          show: true,
          label: '新增',
          permission: ''
        },
        deleteAll:{
          show: true,
          label: '批量删除',
          permission: ''
        },
        export:{
          show: true,
          label: '导出',
          permission: ''
        },
        info:{
          show: true,
          label: '详情',
          permission: ''
        },
        update:{
          show: true,
          label: '修改',
          permission: ''
        },
        delete:{
          show: true,
          label: '删除',
          permission: ''
        }
      },
      type: Object,
    },
    columns:{
      default: [],
      type: Array,
    },
    formConfig:{
      default: [],
      type: Array,
    },
    infoConfig:{
      default: [],
      type: Array,
    },
    searchConfig:{
      default: [],
      type: Array,
    },
    detailConfig:{
      default: [],
      type: Array,
    },
    rules:{
      type: Object
    },
    labelWidth:{
      type: String
    },
  },
  data(){
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      searchData: [],
      searchParam: {},
      formParam: {}
    }
  },
  created() {
    this.setData()
    this.getTableData()
  },
  methods:{
    getValue(row,item){
      if (!row[item.prop]) {
        return ""
      }
      if(item.dict) {
        if (item.type === 'checkbox') {
          let arr = row[item.prop] ? row[item.prop].split(',') : []
          let labelArr = []
          arr.forEach(val=>{
            labelArr.push(this.$filter.getDictLabel(item.dict,val))
          })
          return labelArr.join(',')
        } else {
          return this.$filter.getDictLabel(item.dict,row[item.prop])
        }
      } else if(item.apis){
        return this.getLabels(row[item.prop],item)
      } else if(item.api){
        return this.getLabel(row[item.prop],item)
      } else if(item.format){
        return this.getTimeText(row[item.prop],item)
      } else if(item.unit){
        return row[item.prop] + this.$filter.getDictLabel('count_unit',row[item.unit])
      } else if(item.suf){
        return row[item.prop] + item.suf
      } else if(item.prf){
        return item.prf + row[item.prop]
      } else {
        return row[item.prop]
      }
    },
    getTimeText(value,item){
      return moment(value).format(item.format)
    },
    getLabel(value,item){
      let arr = item.selectData ? item.selectData.filter(obj => obj[item.props.value] === value) : []
      if(arr.length > 0) {
        return  arr[0][item.props.label]
      } else {
        return value
      }
    },
    getLabels(value,item){
      if(value) {
        let arr = value.split(',')
        let labelArr = []
        arr.forEach(obj=>{
          labelArr.push(this.getLabel(obj,item))
        })
        return labelArr.join(',')
      } else {
        return ''
      }
    },
    setData(){
      this.columns.forEach(item=>{
        if (item.api) {
          this.$axios.post(item.api).then(res=>{
            if (item.pObj) {
              item.selectData = res.data.unshift(item.pObj)
            } else {
              item.selectData = res.data
            }
          })
        }
        if (item.apis) {
          this.$axios.post(item.apis).then(res=>{
            if (item.pObj) {
              item.selectData = res.data.unshift(item.pObj)
            } else {
              item.selectData = res.data
            }
          })
        }
      })
    },
    refresh(){
      this.getTableData()
      this.$emit("refresh")
    },
    getTableData(){
      let param = {
        pageSize: this.pageSize,
        pageCurrent: this.currentPage
      }
      if (this.collectName) {
        param.collectName = this.collectName
        if (this.searchData) {
          param.queryParamList = this.searchData
        }
        this.$axios.post('/system/mApi/page',param).then(res=>{
          if (res.code === 200) {
            this.tableData = res.data.list
            this.total = parseInt(res.data.total)
          }
        })
      }
      if (this.api) {
        if (this.searchData) {
          param = Object.assign(param,this.searchData)
        }
        if (this.searchParam) {
          param = Object.assign(param,this.searchParam)
        }
        this.$axios.post(this.api + '/page',param).then(res=>{
          if (res.code === 200) {
            this.tableData = res.data.records
            this.total = parseInt(res.data.total)
          }
        })
      }
    },
    handleAdd(){
      if (this.btnConfig.add.emit) {
        this.$emit('add')
      } else {
        this.$refs.baseForm.show()
      }
    },
    handleEdit(index,row){
      if (this.btnConfig.update.emit) {
        this.$emit('update',row)
      } else {
        this.$refs.baseForm.show(row)
      }
    },
    handleInfo(index,row){
      if (this.btnConfig.info.emit) {
        this.$emit('info',row)
      } else {
        this.$refs.baseInfo.show(row)
      }
    },
    handleDelete(index,row){
      if (this.btnConfig.delete.emit) {
        this.$emit('handleDelete',row)
        return
      }
      ElMessageBox.confirm('确认删除此数据？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText:'取消',
        type: 'warning',
        callback: (action) => {
          if (action === 'confirm'){
            this.$axios.get(this.api + '/deleteById',{id:row.id}).then(res=>{
              if(res.code === 200) {
                this.refresh()
                ElMessage({
                  message: "已删除",
                  type: 'success',
                })
              } else {
                ElMessage({
                  message: res.message,
                  type: 'error',
                })
              }
            })
          } else {
            ElMessage({
              message: "已取消",
              type: 'info',
            })
          }
        },
      })
    },
    handleSizeChange(size){
      this.pageSize = size
      this.getTableData()
    },
    handleCurrentChange(page){
      this.currentPage = page
      this.getTableData()
    },
    search(searchData){
      this.currentPage = 1
      this.searchData = searchData
      this.getTableData()
      this.$emit("search",searchData)
    },
    clear(){
      this.currentPage = 1
      this.searchData = {}
      if (this.collectName) {
        this.searchData = []
      }
      this.getTableData()
      this.$emit("clear")
    },
    getSelectionRows(){
      return this.$refs.tableRef.getSelectionRows()
    }
  }
}
</script>

<style lang="less" scoped>
  .page {
    margin-top: 10px;
    display: flex;
    justify-content: right;
  }
  .base-table {

  }
  .text-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
