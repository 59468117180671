import { createApp } from 'vue'
import App from './App.vue'
import './icons'
const app = createApp(App)

//引入百度地图
import BaiduMap from 'vue-baidu-map-3x'
app.use(BaiduMap, {
    ak: 'W82CsYZLxeSdim5VseLFIZpDlEPB7LWa',
    v: '3.0',
  });

//引入highcharts
// import HighchartsVue from 'highcharts-vue'
// app.use(HighchartsVue)

//引入数字滚动
import AnimatedNumber from 'animated-number-vue3'
app.use(AnimatedNumber)

// 引入字体
import("@/assets/text/font.less")

import vue3SeamlessScroll from "vue3-seamless-scroll";
app.use(vue3SeamlessScroll)

import router from './router'
app.use(router)

import store from './store'
app.use(store)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
app.use(ElementPlus, {
    locale: zhCn,
})

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import yxBpmnDesignNext from 'yx-bpmn-design-next'
import 'yx-bpmn-design-next/dist/yxBpmnDesignNext.css'
app.use(yxBpmnDesignNext)


import util from './utils'
app.config.globalProperties.$util = util

import axios from './axios';
app.config.globalProperties.$axios = axios

import filter from './filter'
app.config.globalProperties.$filter = filter


import SvgIcon from '@/components/svgIcon'
app.component("svg-icon",SvgIcon)

import AtBox from '@/components/atBox'
app.component("at-box",AtBox)

import TownMap from '@/components/townMap/index'
app.component("town-map",TownMap)

import BaseTable from '@/components/baseTable/index'
app.component("base-table",BaseTable)

import BaseSimpleTable from '@/components/baseTable/simpleTable'
app.component("base-simple-table",BaseSimpleTable)

import BaseSearch from '@/components/baseSearch/index'
app.component("base-search",BaseSearch)

import BaseForm from '@/components/baseForm/index'
app.component("base-form",BaseForm)

import BaseInfo from '@/components/baseInfo/index'
app.component("base-info",BaseInfo)


app.mount('#app')





