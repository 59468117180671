<template>
  <div class="base-search flex align-start justify-between" style="padding-top: 20px" :class="{end_height:!showDown}">
    <el-form ref="s_form" :model="searchData" style="width: calc(100% - 300px);" label-width="80px">
      <el-row :gutter="50" >
        <el-col :span="item.span ? item.span : 8" v-for="(item,index) in searchConfig" :key="index">
          <el-form-item :label="item.label" :prop="item.prop" :label-width="item.labelWidth ? item.labelWitdth+'px' : '80px'">

            <el-input v-if="item.type === 'text'" v-model="searchData[item.prop]"></el-input>

            <el-select filterable v-if="item.type === 'select'" v-model="searchData[item.prop]" style="width: 100%;" @change="(e)=>handleSelectChange(e,item)">
              <el-option v-for="(option,idx) in item.selectData" :key="idx" :label="option[item.props.label]" :value="option[item.props.value]" />
            </el-select>

            <el-tree-select v-if="item.type === 'treeSelect'" :props="item.props" :render-after-expand="false"
                            v-model="searchData[item.prop]"  :check-strictly="true" :data="item.selectData" style="width: 100%" />

            <el-date-picker v-if="item.type === 'dateScopeSelect'"
                v-model="searchData[item.prop]"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            />

            <el-date-picker v-if="item.type === 'yearPicker'" style="width: 100%;" value-format="YYYY"
                v-model="searchData[item.prop]"
                type="year"
            />

          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="flex align-center" style="width: 260px;">
      <el-button type="default" v-if="showBtn"  :icon="showDown == true ? 'ArrowDown' : 'ArrowUp'" @click="showDown=!showDown">{{ showDown == true ? '展开' : '收缩' }}</el-button>
      <el-button type="primary"  icon="Search" @click="search">搜索</el-button>
      <el-button type="default"  icon="RefreshLeft" @click="clear">重置</el-button>
    </div>
  </div>
<!--  <div class="arrow_down flex cursor" v-if="showDown" @click="showDown=!showDown"></div>-->
</template>

<script>
let moment = require('moment')
moment.locale('zh-cn')
export default {
  props:{
    searchConfig:{
      default: [],
      type: Array,
    },
    collectName: {
      default: '',
      type: String,
    },
    formData: {
      type: Object
    }
  },
  data(){
    return {
      searchData:{},
      showDown:false,
      showBtn:false
    }
  },
  watch:{
    formData(){
      this.searchData = this.formData
    }
  },
  created() {
    this.setData()
    if(this.searchConfig.length > 3){
      this.showDown = true
      this.showBtn = true
    }
  },
  methods:{
    search(){
      if (this.collectName) {
        let searchParam = []
        this.searchConfig.forEach(item=>{
          if (this.searchData[item.prop]) {
            if(item.type === 'dateScopeSelect') {
              if(param[item.prop]) {
                param[item.start] = moment(param[item.prop][0]).format('YYYY-MM-DD') + ' 00:00:00'
                param[item.end] = moment(param[item.prop][1]).format('YYYY-MM-DD') + ' 23:59:59'
                delete param[item.prop]
              }
              let p1 = {
                field: item.start,
                condition: 'ge',
                value: moment(this.searchData[item.prop][0]).format('YYYY-MM-DD') + ' 00:00:00'
              }
              let p2 = {
                field: item.end,
                condition: 'le',
                value: moment(this.searchData[item.prop][1]).format('YYYY-MM-DD') + ' 00:00:00'
              }
              searchParam.push(p1)
              searchParam.push(p2)
            } else {
              let p = {
                field: item.prop,
                condition: item.condition,
                value: this.searchData[item.prop]
              }
              searchParam.push(p)
            }
          }
        })
        this.$emit('search',searchParam)
      } else {
        let param = Object.assign({},this.searchData)
        this.searchConfig.forEach(item=>{
          if(item.type === 'dateScopeSelect') {
            if(param[item.prop]) {
              param[item.start] = moment(param[item.prop][0]).format('YYYY-MM-DD') + ' 00:00:00'
              param[item.end] = moment(param[item.prop][1]).format('YYYY-MM-DD') + ' 23:59:59'
              delete param[item.prop]
            }
          }
        })
        this.$emit('search',param)
      }
    },
    clear(){
      this.$refs.s_form.resetFields()
      this.$emit('clear')
    },
    setData(){
      this.searchConfig.forEach(item=>{
        if (item.api) {
          this.$axios.post(item.api,item.params ? item.params : {}).then(res=>{
            if (item.type === 'treeSelect') {
              if (item.pObj) {
                item.selectData = []
                item.pObj[item.props.children] = res.data
                item.selectData.push(item.pObj)
              } else {
                item.selectData = res.data
              }
            } else {
              if (item.pObj) {
                res.data.unshift(item.pObj)
                item.selectData = res.data
              } else {
                item.selectData = res.data
              }
            }
          })
        }
        if (item.dict) {
          if (item.pObj) {
            let list = this.$filter.getDictData(item.dict)
            list.unshift(item.pObj)
            item.selectData = list
          } else {
            item.selectData = this.$filter.getDictData(item.dict)
          }
        }
      })
    },
    handleSelectChange(e,val){
      if(!val.toProp) {
        return
      }
      this.searchData[val.toProp] = ''
      this.searchConfig.forEach(item=>{
        if(item.prop === val.toProp) {
          let param = {}
          param[val.targetParam] = this.searchData[val.prop]
          item.params = Object.assign(param)
          this.$axios.post(item.api,item.params ? item.params : {}).then(res=>{
            if (item.type === 'treeSelect') {
              if (item.pObj) {
                item.selectData = []
                item.pObj[item.props.children] = res.data
                item.selectData.push(item.pObj)
              } else {
                item.selectData = res.data
              }
            } else {
              if (item.pObj) {
                item.selectData = res.data.unshift(item.pObj)
              } else {
                item.selectData = res.data
              }
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .base-search {
    width: 100%;
    height: 70px;
    font-family: 'Alibaba-PuHuiTi-Regular';
    background-color: #FFFFFF;
    padding: 10px;
    margin-bottom: 30px;
    //border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  .arrow_down{
    //width: 20px;
    //height: 20px;
    //background-color: #1ED6C7;
    position: absolute;
    left: 48%;
    top: 130px;
    width: 0px;
    height: 0px;
    border-top: 13px solid #999999;
    border-right: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid transparent;
    //background-color: #1ED6C7;
  }
  .end_height{
    height:auto;
  }
</style>
